<template>
  <div class="event">
    <template>
      <el-button class="pt-0" type="text" :disabled="loading" icon="el-icon-back" @click="back">Назад</el-button>
      <template v-if="userHasRole('IncidentsEdit')">
        <el-button v-if="!editMode" :disabled="loading"  type="text" icon="el-icon-edit" @click="editEvent()">Редактировать</el-button>
        <el-button v-if="editMode" :disabled="loading" type="text" icon="el-icon-edit" @click="updateEvent()">Сохранить</el-button>
        <el-button v-if="editMode" type="text" :disabled="loading" @click="cancelEdit()"> <CancelIcon /> Отменить</el-button>
        <el-button v-if="id" class="pt-0" type="text" :disabled="loading" icon="el-icon-delete" @click="handleDelete()">Удалить</el-button>
      </template>
    </template>

    <el-tabs type="border-card" >

        <el-tab-pane label="Основное" >
          <event-general
            ref="eventGeneral"
            :id="id"
            :eventCard="eventCard"/>
        </el-tab-pane>

        <el-tab-pane label="Функции РЗА" :disabled="loading">
          <event-function-cards
            ref="eventFunctionCards"
            :id="id"
            :eventCard="eventCard"
            :getData="getData"
            :functionCardsList="eventCard.functionCardsList"
          />
        </el-tab-pane>

        <el-tab-pane label="Устройства РЗА" :disabled="loading">
          <event-second-equipment-cards
            :id="id"
            :eventCard="eventCard"
            :getData="getData"
            :secondEquipmentCardsList="eventCard.secondEquipmentCardsList"/>
        </el-tab-pane>

        <el-tab-pane label="Комплексы РЗА" :disabled="loading" v-if="!userHasRoleGroup('ExternalUser')">
          <event-second-equipment-type-cards
            :id="id"
            :eventCard="eventCard"
            :getData="getData"
            :secondEquipmentTypeCardsList="eventCard.secondEquipmentTypeCardsList"/>
        </el-tab-pane>

        <el-tab-pane label="Связанные события" :disabled="loading">
          <related-events
            :event-card-id="id"
            :table-size-constant="210"
          />
        </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/api/eventsAndIncidents/events/';
import udEventsApi from '@/api/eventsAndIncidents/userDataEvents/';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import CancelIcon from '@/assets/icons/cancel.svg';
import tableResize from '@/mixins/tableResize.js';

import EventGeneral from '@/views/EventsAndIncidents/Events/Tabs/EventGeneral';
import EventSecondEquipmentCards from '@/views/EventsAndIncidents/Events/Tabs/EventSecondEquipmentCards';
import EventSecondEquipmentTypeCards from '@/views/EventsAndIncidents/Events/Tabs/EventSecondEquipmentTypeCards';
import EventFunctionCards from '@/views/EventsAndIncidents/Events/Tabs/EventFunctionCards';
import RelatedEvents from '@/views/EventsAndIncidents/EventsMatch/RelatedEvents';

export default {
  name: 'Event',
  props: ['id'],
  mixins: [formatters, dateHelpers, tableResize],
  components: {
    RelatedEvents,
    EventGeneral,
    EventSecondEquipmentCards,
    EventSecondEquipmentTypeCards,
    CancelIcon,
    EventFunctionCards,
    },
  data() {
    return {
      loading: false,
      editMode: false,
      primaryEquipments: [],
      eventCard: {},
    };
  },
  async created() {
    await this.getData(this.id);
  },
  computed: {
    eventDate: {
      get() {
        return this.dateGetter(this.eventCard.eventTime);
      },
      set(val) {
        this.eventCard.eventTime = this.dateSetter(val);
      }
    },
    ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup']),
  },
  methods: {
    async getData(eventCardId) {
      this.loading = true;
      if (this.userHasRoleGroup('ExternalUser')) {
        const res = await udEventsApi.getEventCard(eventCardId);
        if (res.data) {
          this.eventCard = res.data;
        }
      } else {
        const res = await eventsApi.getEventCard(eventCardId);
        if (res.data) {
          this.eventCard = res.data;
        }
      }
      this.loading = false;
    },
    back() {
      if (this.$route.fullPath.includes('/incident')) {
        this.$router.push({
            path: '/events-incidents/incidents/'.concat(this.eventCard.incidentId).concat('?tab=events'),
        });
        // eslint-disable-next-line brace-style
      }
      /* if (this.$route.params.incidentId) {
        this.$router.push({
          path: '/events-incidents/incidents/'.concat(this.$route.params.incidentId)
        });
      } */
      else {
        this.$router.push({
            path: '/events-incidents/events'
        });
      }
    },
    editEvent() {
      this.$root.$emit('editEvent', {
          editMode: true,
        });
      this.editMode = true;
    },
    validateForm() {
      const result = this.$refs.eventGeneral.$refs.eventCardForm.validate();
      return result;
    },
    async updateEvent() {
      const valid = await this.validateForm();
      if (!valid) return;
      this.loading = true;

      const updateAction = this.userHasRoleGroup('ExternalUser')
        ? udEventsApi.updateEvent
        : eventsApi.updateEvent;

      updateAction(this.eventCard).then(async () => {
            this.dialogVisible = false;
            await this.getData(this.id);
            this.loading = false;
            this.$root.$emit('editEvent', {
                editMode: false,
              });
            this.editMode = false;
            this.loading = false;
      });
    },
    cancelEdit() {
      this.loading = true;
      this.getData(this.id).then(async () => {
        this.$root.$emit('editEvent', {
          editMode: false,
        });
        this.editMode = false;
        this.loading = false;
      });
    },
    handleDelete() {
      this.$confirm('Вы действительно хотите удалить запись?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        const deleteAction = this.userHasRoleGroup('ExternalUser')
          ? udEventsApi.delete
          : eventsApi.delete;

        const deleteResult = await deleteAction(this.id);

        if (deleteResult.data.succeeded === false) {
          this.$alert(deleteResult.data.resultMessage, 'Ошибка');
        } else {
          this.$router.push({ name: 'Events' });
        }
      }).catch(() => { });
    },
  }
};
</script>

<style scoped lang="scss">
  svg {
    vertical-align: bottom;
  }
  .el-dialog__wrapper.el-dialog.el-dialog__body{
    padding: 20px;
  }
  .el-radio-group {
      font-size: initial !important;
  }
  .el-radio-group {
    width: 100%;
  }
  span.el-radio__label {
    padding-left: 5px;
  }
  span.el-radio__label>span {
    padding: 0px 5px;
  }
  span.el-radio__inner {
    margin: 0px 0px 9px 0px;
  }
  .el-tabs__header.is-top {
    margin: 0px !important;
  }
  /*button.el-button.el-button--text {
    color: #303133;
    margin: 0px 15px;
  }*/
  button.el-button.el-button--text:first-child {
    margin-left: 0px;
  }
  .el-col>span {
    font-size: 12px;
  }
  button.el-button.el-button--text:hover {
    color: #3078bf;
  }
  .el-select {
    width: 100%;
  }
  span {
    //white-space: nowrap;
    vertical-align: middle;
  }
  .dialog-footer {
    text-align: center;
  }
  .scrollableTree {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 420px;
  }
  .tree-item>span>i {
    margin: 0px 5px 0px 0px;
  }
  .tree-item > span {
    font-size: 14px;
  }
  .technical-reason--tree{
    font-size: 12px;
    .node__content {
      height: auto;
    }
    .custom-tree-node{
      .tree-item > span {
      white-space: normal;
      font-size: 14px;
      }
    }
  }
</style>
